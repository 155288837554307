import React, { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import omit from 'lodash/omit';
import { Row, Col, Grid } from 'antd';
import { Button, Modal, Tooltip, Card, Statistic, FatLanguageButton } from '../../../components';
import Metatab from '../Metatab';
import { voidFunction } from '../../../utils';
import { CloseButton } from '../../../shared';
import CustomIcon from '../../CustomIcon';
import VirtualTable from '../../Table/VirtualTable';
import DataViewRowAndIndicators from '../DatasetUtils/DataViewRowAndIndicators';
import { RoutePaths } from '../../../routePaths';
import { ALL_DOMAINS, FISC_VARIANT } from '../../../constants';

import s from '../index.module.less';
import { checkStandarisation } from '../../../utils/common';
import useNavigate from '../../Hooks/useNavigate';

const ProfileTab = (props) => {
  const screens = Grid.useBreakpoint();
  const history = useHistory();
  const navigate = useNavigate();

  const format = (d) => d.toString().replace(/\w+ (\w+) (\d+) (\d+).*/, '$1 $2, $3');
  const {
    total = 0,
    viewReportClick = voidFunction,
    details = {},
    preview = [],
    tableCols = [],
    profile = [],
    dataset,
    downloadEntireDataset = voidFunction,
  } = props;
  const [moreDetailsPopup, setMoreDetailsPopup] = useState(false);
  const metaReference = useRef(null);
  const dataSource = useMemo(
    () =>
      preview?.map((item) => {
        const k = Object.keys(item).filter((f) => f.includes('Code'));

        return omit(item, k);
      }),
    [preview]
  );

  const isStandarised = checkStandarisation(details);

  return (
    <>
      <Row gutter={20}>
        <Col xs={{ span: 22 }} xl={{ span: 11 }}>
          <h3 id="overview-section" className="joyride-dataset-step9">
            {t('Overview')}
          </h3>
          <h5 className="font-14 font-weight-normal text-justify">{details?.DatasetInformation}</h5>
          <Row className="pb-4">
            <Col>
              <div>
                <Tooltip title={t('DocumentLinkSource')} placement="right">
                  <Button
                    size="small"
                    type="link"
                    className="p-0 font-14 joyride-dataset-step10"
                    onClick={() => setMoreDetailsPopup(true)}
                  >
                    {t('MoreInfo')}
                  </Button>
                </Tooltip>
                <Modal
                  open={moreDetailsPopup}
                  closable={false}
                  onCancel={() => setMoreDetailsPopup(false)}
                  onOk={() => setMoreDetailsPopup(false)}
                  footer={false}
                >
                  <Row justify="space-between">
                    <Col>
                      <h3>{t('ForMoreDetailsCheck')}</h3>
                    </Col>
                    <Col>
                      <CloseButton onClick={() => setMoreDetailsPopup(false)} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ul>
                        {details?.DocumentLinks?.split('||')?.map((link) => (
                          <li key={link}>
                            {link && link !== 'N.A' ? (
                              <a rel="noreferrer" href={link} target="_blank">
                                {link?.trim()}
                              </a>
                            ) : (
                              'N.A'
                            )}
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <Col xs={24}>
                      <h3>{t('SourceLinks')}</h3>
                    </Col>
                    <Col>
                      <ul>
                        {details?.SourceLink?.split('||').map((link) => (
                          <li key={link}>
                            {details.ActualDomain === ALL_DOMAINS.kadap ? (
                              link?.trim()
                            ) : (
                              <a rel="noreferrer" href={link} target="_blank">
                                {link?.trim()}
                              </a>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                </Modal>

                <CustomIcon className={`mr-1 ml-1`} width="6" height="6" type={'EllipseIcon'} verticalAlign={0} />
                <Button
                  size="small"
                  type="link"
                  className="p-0 font-14"
                  onClick={() => {
                    if (metaReference?.current?.scrollIntoView)
                      metaReference.current.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
                  }}
                >
                  {t('JumpMetaData')}
                  <CustomIcon className={`m-0`} type={'DoubleArrowDown'} verticalAlign={5} />
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 22 }} xl={{ span: 13 }}>
          <Card className="">
            <Row>
              <Col span={24} md={12} xl={12} className="d-flex">
                <img src="/static/assets/images/icon_source.svg" alt="Collection" className="align-self-start pr-2" />
                <Tooltip
                  title={details.HeaderDefinitions?.Source || t('SourceDepartment')}
                  placement="top"
                  className="cursor-pointer"
                >
                  <Statistic
                    title={t('SourceDepartment')}
                    value={details?.MinistryName ? details?.MinistryName : details?.DepartmentName || 'NA'}
                  ></Statistic>
                </Tooltip>
              </Col>
              <Col span={24} md={12} xl={12} className="d-flex ">
                <img src="/static/assets/images/icon_source.svg" alt="Collection" className="align-self-start pr-2" />
                <Tooltip
                  title={details.HeaderDefinitions?.Sector || t('Sector')}
                  placement="top"
                  className="cursor-pointer"
                >
                  <Statistic title={t('Sector')} value={details?.SectorName || 'NA'}></Statistic>
                </Tooltip>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col span={24} md={12} xl={12} className="d-flex ">
                <CustomIcon type={'Collection'} className="align-self-start pr-1 greyFilter" width={30} />
                <Tooltip
                  title={details.HeaderDefinitions?.DatasetCollection || t('Collections')}
                  placement="top"
                  className="cursor-pointer"
                >
                  <Statistic
                    title={t('Collections')}
                    valueRender={() => (
                      <Button
                        title={t('ClickToRedirect')}
                        type="link"
                        className="p-0 h-auto font-16 text-wrap text-left"
                        onClick={() => {
                          history.push(`${RoutePaths.COLLECTION}/${details.DatasetCollection}/${details.CollectionID}`);
                        }}
                      >
                        {details.DatasetCollection}
                      </Button>
                    )}
                  ></Statistic>
                </Tooltip>
              </Col>
              <Col span={24} md={12} xl={12} className="d-flex">
                <img src="/static/assets/images/icon_source.svg" alt="Collection" className="align-self-start pr-2" />
                <Statistic
                  title={t('DatasetHostedBy')}
                  value={details?.ActualDomain?.toUpperCase() || 'NA'}
                ></Statistic>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={24} md={8} xl={8} className="d-flex">
                <img
                  src="/static/assets/images/icon_geocoverage.svg"
                  alt="Geo Coverage"
                  className="align-self-start pr-2"
                />
                <Tooltip
                  title={details.HeaderDefinitions?.GeographicalCoverage || t('Geo_Coverage')}
                  placement="top"
                  className="cursor-pointer"
                >
                  <Statistic title={t('Geo_Coverage')} value={details?.LocalityGranularity || 'NA'}></Statistic>
                </Tooltip>
              </Col>

              <Col xs={24} md={8} xl={8} className="d-flex">
                <img src="/static/assets/images/icon_frequency.svg" alt="Frequency" className="align-self-start mr-3" />
                <Tooltip
                  title={details.HeaderDefinitions?.TimeGranularity || t('Time_Granularity')}
                  placement="top"
                  className="cursor-pointer"
                >
                  <Statistic title={t('Time_Granularity')} value={details?.timeGranulity || 'NA'}></Statistic>
                </Tooltip>
              </Col>

              <Col xs={24} md={8} xl={8} className="text-center">
                <FatLanguageButton
                  text={t('ViewProcessingReport')}
                  type="link"
                  onClick={viewReportClick}
                  className={`${s.viewProcessing} m-0 mt-2 joyride-dataset-step11`}
                  disabled={!isStandarised}
                  tooltip={
                    !isStandarised
                      ? t('DisableDueToNotStandarized', {
                          feature: t('ViewProcessingReport'),
                        })
                      : ''
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={24} md={8} xl={8} className="d-flex">
                <img src="/static/assets/images/icon_frequency.svg" alt="Frequency" className="align-self-start mr-3" />
                <Tooltip
                  title={details.HeaderDefinitions?.RefreshFrequency || t('Frequency')}
                  placement="top"
                  className="cursor-pointer"
                >
                  <Statistic title={t('Frequency')} value={details?.UpdateFrequency || 'NA'}></Statistic>
                </Tooltip>
              </Col>
              <Col xs={24} md={8} xl={8} className="d-flex">
                <img
                  src="/static/assets/images/icon_yearrange.svg"
                  alt="Year Range"
                  className="align-self-start pr-2"
                />
                {!details?.Snapshot ? (
                  <Tooltip
                    title={details.HeaderDefinitions?.YearRange || t('Year_Range')}
                    placement="top"
                    className="cursor-pointer"
                  >
                    <Statistic
                      title={t('Year_Range')}
                      value={`${FISC_VARIANT[details?.Fiscvariant] || ''} ${details?.FromTimerange || 'NA'} ${
                        details?.FromTimerange !== details?.ToTimerange
                          ? `- ${FISC_VARIANT[details?.Fiscvariant] || ''} ${details?.ToTimerange || 'NA'}`
                          : ''
                      }`}
                    ></Statistic>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={details.HeaderDefinitions?.AsOnDate || t('AsOnDate')}
                    placement="top"
                    className="cursor-pointer"
                  >
                    <Statistic
                      title={t('AsOnDate')}
                      value={details.SnapshotDate ? format(new Date(details?.SnapshotDate).toDateString()) : 'NA'}
                    ></Statistic>
                  </Tooltip>
                )}
              </Col>

              <Col xs={24} md={8} xl={8} className="d-flex">
                <img
                  src="/static/assets/images/icon_lastupdated.svg"
                  alt="Last Updated On"
                  className="align-self-start pr-2"
                />
                <Tooltip
                  title={details.HeaderDefinitions?.LastRefreshDate || t('DateUpdatedOn')}
                  placement="top"
                  className="cursor-pointer"
                >
                  <Statistic
                    title={t('DateUpdatedOn')}
                    value={details.LastUpdatedDate ? format(new Date(details?.LastUpdatedDate).toDateString()) : 'NA'}
                  ></Statistic>
                </Tooltip>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <div className={`${s.dataPreview} ${screens.xs && 'mb-0'} ${screens.md && 'mb-1'}`}>
        <Row>
          <Col xs={8} md={12} xl={12}>
            <h4 className="joyride-dataset-step12">{t('Data_Preview')}</h4>
          </Col>
          <Col xs={16} md={12} xl={12} className="text-right">
            <DataViewRowAndIndicators
              mergeResult={dataset}
              view={2}
              colorCode={0}
              total={total}
              details={details}
              allIndicators={true}
            />
          </Col>
          <Col span={24}>
            <h5 className={s.textGreyed}>
              <CustomIcon type={'PrimaryKey'} verticalAlign={6} className="color-blue" /> {t('Primary_Key_Dataset')}
            </h5>
          </Col>
        </Row>
        <VirtualTable
          pagination={false}
          size="small"
          scroll={{ x: '100%', y: 215 }}
          dataSource={dataSource?.slice(0, 5)}
          columns={tableCols.map((item, i) => ({ ...item, key: `${i}` }))}
          tableLayout="fixed"
        />
        <Row>
          <Col>
            <h5 className={`font-14 font-weight-normal mt-2`}>
              {t('PreviewInfo')}
              <Button
                className={`p-0`}
                type="link"
                onClick={() => history.push(`${RoutePaths.DATASET}/${details.SourceId}?tab=data`)}
              >
                {t('ViewDataTable')}
              </Button>{' '}
              {t('Or')}{' '}
              <Button className={`p-0`} type="link" onClick={downloadEntireDataset}>
                {t('ExportDataset')}{' '}
              </Button>
            </h5>
          </Col>
        </Row>
      </div>
      <div>
        <Metatab profile={profile} details={details} total={total} reference={metaReference} />
      </div>
      <div className="m-3">
        <Row justify="center" align="middle">
          <Col
            xs={20}
            md={10}
            xl={10}
            className={`bg-color-light-white rounded p-3 text-center ${
              !screens.lg ? '' : 'd-flex align-items-center justify-content-center joyride-dataset-step15'
            }`}
          >
            <p className="mr-4 my-2">{t('API_Description')}</p>

            <Button
              type="primary"
              ghost
              size="large"
              className={'font-weight-semi-bold rounded bg-white'}
              onClick={() => navigate(`${RoutePaths.GET_API}/${details.SourceId}`)}
            >
              {t('Get_API')}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProfileTab;
