import React from 'react';
import * as Sentry from '@sentry/browser';
import { Modal, Form, Input, Button, message } from '../../../components';
import { CloseButton } from '../../../shared';
import { t } from 'i18next';
import { InputRegex } from '../../../constants';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { voidFunction } from '../../../utils';
import { changePassword } from '../../../services';
import { LoadingOutlined } from '@ant-design/icons';

const ChangePassword = ({ show = false, width = 400, back = voidFunction, onClose = voidFunction }) => {
  const [form] = Form.useForm();
  const [isUpdating, setUpdating] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setUpdating(true);
      await changePassword(values.currentpassword, values.newpassword, values.confirmpassword);

      // Clear sessions across all tabs and windows
      sessionStorage.clear();

      message.success(t('PasswordUpdated'));
      setUpdating(false);
      handleOnClose();

      // Broadcast logout event to all tabs
      localStorage.setItem('logoutEvent', Date.now().toString());
    } catch (error) {
      setUpdating(false);
      message.error(error || t('PasswordUpdatedFailed'));
      Sentry.captureException(`Failed to update password, ` + error);
    }
  };

  const handleOnClose = () => {
    onClose();
    form.resetFields();
  };

  return (
    <Modal
      title={[
        <ArrowLeftOutlined key={'arrow'} onClick={back} className="ml-n1 mr-2" />,
        <React.Fragment key={'header'}>{t('Change_Pwd')}</React.Fragment>,
      ]}
      open={show}
      width={width}
      footer={null}
      onCancel={handleOnClose}
      closeIcon={<CloseButton />}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{}}
        autocomplete="new-password"
        onFinish={!isUpdating && handleSubmit}
      >
        <Form.Item
          name="currentpassword"
          label={t('Current_pwd')}
          required
          rules={[{ required: true, message: t('Current_pwd') + t('isRequired') }]}
        >
          <Input.Password
            name="currentpassword"
            placeholder={t('Current_pwd')}
            size="large"
            autocomplete="new-password"
          />
        </Form.Item>

        <Form.Item
          name="newpassword"
          label={t('New_pwd')}
          required
          rules={[
            { required: true, message: t('New_pwd') + t('isRequired') },
            { pattern: InputRegex.Password, message: t('ValidPasswordMessage') },
          ]}
        >
          <Input.Password name="newpassword" placeholder={t('New_pwd')} size="large" autocomplete="new-password" />
        </Form.Item>

        <Form.Item
          name="confirmpassword"
          label={t('Confirm_pwd')}
          required
          rules={[
            { required: true, message: t('Confirm_pwd') + t('isRequired') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newpassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password name="confirmpassword" placeholder={t('Confirm_pwd')} size="large" autoComplete="off" />
        </Form.Item>

        <Button type="primary" className="mt-4 w-100" size="large" htmlType="submit">
          {isUpdating ? <LoadingOutlined spin /> : null}
          {isUpdating ? t('UpdatingPassword') : t('UpdatePassword')}
        </Button>
      </Form>
    </Modal>
  );
};

export default ChangePassword;
