import React, { useEffect, useState } from 'react';
import { Popover, Button, Badge } from '../../components';
import Icon, { DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import SelectOptions from './SelectOptions';
import './index.module.less';

const Multiselect = ({
  data,
  text,
  placeholder = 'Search',
  onCancel = () => {},
  onApply = () => {},
  apply = {},
  fullWidth = false,
  showOnEmpty = false,
  isLoading = false,
  ...rest
}) => {
  const [isVisible, setVisibility] = useState(false);
  const [checked, setCheckedList] = useState(apply);
  const [dataSource, setDatasource] = useState(data);

  useEffect(() => {
    setDatasource(data);
    setVisibility(false);
  }, [data]);

  const handleCancel = (changeStatus) => {
    if (isVisible !== changeStatus) setVisibility(changeStatus);
    onCancel();
  };

  const handleChange = (checkedList) => {
    setCheckedList(checkedList);
    onApply(checkedList);
    setVisibility(false);
  };

  if (!dataSource?.length && !showOnEmpty) {
    return null;
  }

  const selections = Object.keys(checked).length;
  const handleSetVisibility = () => (isLoading ? null : setVisibility(!isVisible));

  return (
    <Popover
      open={isVisible}
      onOpenChange={handleCancel}
      trigger="click"
      placement="bottom"
      content={
        <SelectOptions
          data={dataSource}
          searchKey={'display'}
          emptySearchValue={[]}
          placeholder={t(placeholder)}
          onApply={handleChange}
          onCancel={handleCancel}
          selectedValues={checked}
          isVisible={isVisible}
          {...rest}
        />
      }
    >
      <Button
        type="outline"
        onClick={!isLoading && handleSetVisibility}
        className={`pr-3 rounded ${fullWidth ? 'w-100 text-left' : ''}`}
        disabled={isLoading}
      >
        <Badge count={selections} style={{ zIndex: 1 }} offset={[-6, -4]}></Badge>
        {t(text)}

        <Icon component={isLoading ? LoadingOutlined : isVisible ? UpOutlined : DownOutlined} className="font-12" />
      </Button>
    </Popover>
  );
};

export default Multiselect;
