import { useMemo, useState } from 'react';
import SelectOptions from '../../MultiselectDropdown/SelectOptions';
import { Modal, Button, Tag, Tooltip, Condition } from '../../../components';
import { CloseButton } from '../../../shared';
import { t } from 'i18next';
import CustomIcon from '../../CustomIcon';
import sortBy from 'lodash/sortBy';

const ColumnFilters = (props) => <SelectOptions {...props} />;

export const ColumnFilterInput = (props) => {
  const [columnPopupVisible, setColumnPopUpVisible] = useState(false);
  const data = sortBy(props?.data, ['DisplayName']);

  const onColumnApply = (columns) => {
    setColumnPopUpVisible(false);

    if (props.onColumnApply) {
      props.onColumnApply(columns);
    }
  };

  const togglePopover = () => setColumnPopUpVisible(!columnPopupVisible);
  const filterProps = { ...props, data, onApply: onColumnApply, onCancel: togglePopover, showCancel: false };

  let selectedCount = useMemo(() => {
    if (props.reverseSelection) {
      return Object.keys(props.selectedValues || {}).filter((key) => !props.selectedValues[key]).length;
    } else {
      return Object.keys(props.selectedValues || {}).length;
    }
  }, [props.reverseSelection, props.selectedValues]);

  const button = (
    <Button
      type="outline"
      onClick={togglePopover}
      {...(props.buttonProps || {})}
      className="btn-gray rounded joyride-MergeResultData-Step5"
      icon={<CustomIcon type={'ShowAndHide'} className="ml-n1" height={16} />}
      shape="round"
    >
      <span className="ml-1">
        {t('ShowHideColumns')}

        {(selectedCount && <Tag className="mr-n2 ml-2 rounded bg-primary">{selectedCount}</Tag>) || null}
      </span>
    </Button>
  );

  return (
    <>
      <Condition show={Boolean(columnPopupVisible)}>
        <Modal
          open={true}
          onCancel={() => setColumnPopUpVisible(false)}
          title={t('ShowHideTitle')}
          footer={null}
          closeIcon={<CloseButton />}
        >
          <ColumnFilters {...filterProps} />
        </Modal>
      </Condition>

      <Condition show={Boolean(selectedCount)}>
        <Tooltip title={t(props.reverseSelection ? 'TotalHidingCol' : 'TotalShowingCol', { count: selectedCount })}>
          {button}
        </Tooltip>
      </Condition>
      <Condition show={!Boolean(selectedCount)}>{button}</Condition>
    </>
  );
};
export default ColumnFilters;
