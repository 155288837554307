import { useState } from 'react';
import { t } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';

import { Modal, Button } from '../../components';
import DatasetIndicaterFilter from './DatasetIndicaterFilter';
import DatasetModalTitle from '../DatasetModalTitle';
import { useFilterContext, useAppliedFilterContext, useFilterActions } from '../../providers/FilterContextProvider';
import { CloseButton } from '../../shared';
import { TYPE_INDICATORS, WEIGHTED_AVG } from '../../constants';
import { voidFunction } from '../../utils';

const WEIGHTED_AVG_VALUES = Object.values(WEIGHTED_AVG);
const WEIGHTED_AVG_KEYS = Object.keys(WEIGHTED_AVG);

const DatasetIndicator = ({
  sourceID,
  sourceName = '',
  indicaters = [],
  applyModalTo = null,
  colorCode = 0,
  icon = 'source',
  allow = false,
  isGraphIndicater = false,
  type = TYPE_INDICATORS,
  merge = false,
  forcedShow = false,
  selectAllByDefault = false,
  disableAggregation = false,
  onApplyClick = voidFunction,
}) => {
  indicaters = cloneDeep(indicaters);
  let { clearFilters, showFilter, getAny, setChecked } = useFilterContext();
  let { resetToPrevious } = useFilterActions({ sourceID, type });
  let { setAppliedFilter } = useAppliedFilterContext();
  const [resetSearch, setResetSearch] = useState(false);

  const onApply = () => {
    const data = getAny({
      type,
      sourceID,
      key: 'checked',
    });
    setAppliedFilter({
      appliedFilter: data,
      quickPaths: getAny({
        type,
        sourceID,
        key: 'quickPaths',
      }),
      type,
      sourceID,
    });
    clearFilters();
    setResetSearch((ps) => !ps);
    onApplyClick(data);
  };

  if (!sourceID) return null;

  return (
    <Modal
      onCancel={() => {
        resetToPrevious();
        setResetSearch((ps) => !ps);
      }}
      title={<DatasetModalTitle title={'SelectIndicaters'} sourceName={sourceName} colorCode={colorCode} icon={icon} />}
      width={'70%'}
      style={{ top: 40 }}
      open={showFilter({ sourceID, type }) || forcedShow}
      getContainer={applyModalTo}
      footer={
        <Button type="primary" onClick={onApply}>
          {t('Apply')}
        </Button>
      }
      closable={!forcedShow}
      closeIcon={<CloseButton />}
    >
      <DatasetIndicaterFilter
        isGraphIndicater={isGraphIndicater}
        indicaters={indicaters.map((item) => {
          if (!item.AggregationFunction) {
            item.AggregationFunction = 'sum';
          }

          const index = WEIGHTED_AVG_VALUES.findIndex((key) => key === item.AggregationFunction);
          if (index > -1) {
            item.AggregationFunction = WEIGHTED_AVG_KEYS[index];
          }

          return item;
        })}
        onSelect={(data) => {
          setChecked({
            sourceID,
            type,
            data,
          });
        }}
        height={'30vh'}
        quickPaths={getAny({
          type,
          sourceID,
          key: 'quickPaths',
        })}
        allow={allow}
        merge={merge}
        disableAggregation={disableAggregation}
        selectAllByDefault={selectAllByDefault}
        resetSearch={resetSearch}
      />
    </Modal>
  );
};

export default DatasetIndicator;
