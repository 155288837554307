import { useState, useEffect, Fragment } from 'react';
import { t } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { Modal, Button, Select, Row, Col, Input, Empty, Tag } from '../../components';
import CustomIcon from '../CustomIcon';
import {
  CONDITION_AND,
  CONDITION_WHERE,
  GridFixedSpan,
  CONDITION_OPERATERS,
  TYPE_OPERATOR,
  TYPE_COLUMN,
  TYPE_CONDITION,
  TYPE_VALUE,
  TYPE_INDICATORS,
} from '../../constants';
import { voidFunction } from '../../utils';
import { useAppliedFilterContext } from '../../providers/FilterContextProvider';
import { CloseButton } from '../../shared';
import DimensionIcon from '../CustomIcon/DimensionIcon';

const { Option } = Select;

const constrains = {
  skip: true,
  [TYPE_OPERATOR]: false,
  [TYPE_COLUMN]: false,
  [TYPE_CONDITION]: 'GT',
  [TYPE_VALUE]: false,
};

const ConstrainInput = ({
  index,
  operator,
  column,
  condition,
  value,
  columnList = [],
  onChange = voidFunction,
  onRemove = voidFunction,
  conditionType = TYPE_CONDITION,
}) => {
  const handleOnChange = (type, value) => onChange(index, type, value);

  return (
    <Row className="mb-3" gutter={[10, 0]}>
      <Col {...GridFixedSpan.size.two}>
        <Row>
          <Col span={5} className="text-left pt-1">
            <MinusCircleOutlined onClick={() => onRemove(index)} title={t('RemoveCondition')} />
          </Col>
          <Col span={19}>
            <div className="pt-1">{operator}</div>
            {/* 
            WIP : Code if required to add OR operator conditions
            {!index ? (
              <div className="pt-1">{operator}</div>
            ) : (
              <Select className="w-100 " value={operator} onChange={(e) => handleOnChange(TYPE_OPERATOR, e)}>
                {CONDITION.map((conditionType) => (
                  <Option value={conditionType} key={conditionType}>
                    {conditionType}
                  </Option>
                ))}
              </Select>
            )} */}
          </Col>
        </Row>
      </Col>

      <Col {...GridFixedSpan.size.eight}>
        <Row>
          <Col xs={24} md={12} xl={13}>
            <Select
              showSearch={true}
              type="dashed"
              className="w-100 "
              placeholder={t('AddColumns')}
              value={column || undefined}
              onChange={(e) => {
                handleOnChange(TYPE_COLUMN, e);
              }}
              filterOption={(input, option) => {
                let string = option.children;
                if (!string) {
                  string = '';
                }

                if (Array.isArray(string)) {
                  string = string.filter((item) => item).toString();
                }

                return string.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              notFoundContent={<Empty className="text-dark" description={t('NoData')} />}
            >
              {columnList.map((item) => {
                const icon =
                  (item.colorIndicator > -1 && (
                    <DimensionIcon
                      merge={true}
                      colorCode={item.colorIndicator - 1}
                      type={TYPE_INDICATORS}
                      verticalAlign={3}
                    />
                  )) ||
                  null;

                return (
                  <Option value={item.ID} key={item.ID} title={item.Description}>
                    {icon}
                    {item.Description}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col className="text-center pt-1 pr-2 pl-2">{t('IS')}</Col>
          <Col {...GridFixedSpan.size.four}>
            <Select
              className="w-100 btn-gray"
              value={condition}
              allowClear={false}
              onChange={(e) => handleOnChange(TYPE_CONDITION, e)}
            >
              {CONDITION_OPERATERS.map((item) => (
                <Option value={item.key} key={item.key}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Col>

      <Col {...GridFixedSpan.size.two}>
        <Input
          placeholder="0000"
          type={'number'}
          value={value || ''}
          min={0}
          onChange={(e) => {
            let value = e.target.value;
            handleOnChange(TYPE_VALUE, value);
          }}
          autoComplete="off"
        />
      </Col>
    </Row>
  );
};

const allChecked = (state) => {
  let isTrue = false;
  let count = 0;

  if (!state.length) {
    return { isTrue: true, count };
  }

  state.every((item) => {
    let values = Object.values(item);
    values.every((v) => {
      if (v || v === 0) {
        isTrue = true;
        return true;
      }

      isTrue = false;
      return false;
    });

    count++;
    return isTrue;
  });

  return { isTrue, count };
};

const Conditions = ({
  onApply = voidFunction,
  columnList = [],
  appliedConditions = [],
  sourceID,
  applyModalTo = null,
  conditionType = TYPE_CONDITION,
}) => {
  const { setAppliedFilter, getSelectedFilters } = useAppliedFilterContext();
  const [isVisible, setVisible] = useState(false);
  const selectedFilters = sourceID ? getSelectedFilters({ sourceID, type: conditionType }) : false;
  const [conditions, setConditions] = useState(selectedFilters || appliedConditions);
  const [canApply, setCanApply] = useState({ isTrue: false, count: 0 });

  const toggleModal = () => {
    setVisible((visibility) => {
      if (selectedFilters.length && sourceID) setConditions(cloneDeep(selectedFilters));
      else setConditions(cloneDeep([]));
      return !visibility;
    });
  };

  const conditionCount = conditions.length;

  const handleOnApply = () => {
    onApply(conditions);
    setVisible(false);

    if (sourceID) {
      setAppliedFilter({
        sourceID,
        type: conditionType,
        appliedFilter: conditions,
      });
    }
  };

  const addConstrains = () => {
    setConditions((ps) => {
      let state = [
        ...ps,
        {
          ...constrains,
          operator: conditionCount ? CONDITION_AND : CONDITION_WHERE,
        },
      ];

      setCanApply(false);

      return state;
    });
  };

  const removeConstrain = (index) => {
    setConditions((ps) => {
      let state = [...ps];
      state.splice(index, 1);

      if (state.length === 1 || state[0]) {
        state[0][TYPE_OPERATOR] = CONDITION_WHERE;
      }

      setCanApply(allChecked(state));
      return state;
    });
  };

  const onChange = (index, type, value) => {
    setConditions((ps) => {
      let state = [...ps];
      state[index][type] = value;

      setCanApply(allChecked(state));
      return state;
    });
  };

  useEffect(() => {
    setConditions((ps) => {
      if (!ps.length) {
        let state = [
          ...ps,
          {
            ...constrains,
            operator: CONDITION_WHERE,
          },
        ];

        setCanApply(allChecked(state));
        return state;
      }

      setCanApply(allChecked(ps));

      return ps;
    });
  }, []);

  return (
    <Fragment>
      <Button
        icon={<CustomIcon type={'Conditions'} width={16} verticalAlign={4} className="ml-n1" />}
        className="btn-gray d-flex rounded joyride-MergeResultData-Step4 joyride-MergeResultDataVisualize-Step2"
        onClick={toggleModal}
      >
        {t('Conditions')}

        {selectedFilters?.length ? (
          <Tag className="ml-2 mr-n2 rounded  bg-primary">{selectedFilters.length}</Tag>
        ) : null}
      </Button>
      <Modal
        maskClosable={false}
        style={{ top: '6vh' }}
        title={t('ConditionsHeader')}
        open={isVisible}
        onCancel={toggleModal}
        getContainer={applyModalTo}
        footer={[
          <Button
            disabled={!canApply.isTrue}
            type="primary"
            size="large"
            onClick={handleOnApply}
            shape="round"
            key={'apply'}
            className="rounded"
          >
            {t('Apply')}
          </Button>,
        ]}
        width={'70%'}
        bodyStyle={{ padding: 0 }}
        closeIcon={<CloseButton type="black" />}
      >
        {columnList.length ? (
          <Fragment>
            <div style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }} className="pt-4 pr-4 pl-4 pb-2">
              {conditions.map((item, index) => (
                <ConstrainInput
                  {...item}
                  index={index}
                  key={`${index}`}
                  onRemove={removeConstrain}
                  onChange={onChange}
                  columnList={columnList}
                  conditionType={conditionType}
                />
              ))}
            </div>

            {!conditionCount ? (
              <Empty
                description={
                  <div>
                    {t('NoConstraints')}
                    <Button
                      disabled={conditions?.length >= 10 ? true : false}
                      type="link"
                      onClick={addConstrains}
                      className="pl-1 pr-0 pt-0 pb-0"
                    >
                      {t('AddCondition')}
                    </Button>
                  </div>
                }
              />
            ) : (
              <Button
                disabled={conditions?.length >= 10 ? true : false}
                type="link"
                onClick={addConstrains}
                icon={<PlusOutlined />}
                className="mb-2"
              >
                {t('AddAnotherCondition')}
              </Button>
            )}
          </Fragment>
        ) : (
          <Empty description={t('NoConstraints')} />
        )}
      </Modal>
    </Fragment>
  );
};

export default Conditions;
