import React, { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/browser';

import { useAppliedFilterContext } from '../../providers/FilterContextProvider';
import Context from '../../context';
import { Tooltip, Modal, Form, Input, FatLanguageButton } from '../../components';
import { CloseButton } from '../../shared';
import { setBookmarkDetails, postClickStreamEvent } from '../../services';
import { ACTIVITIES, InputRegex } from '../../constants';
import { BookOutlined } from '@ant-design/icons';
import { t } from 'i18next';
// WIP: Just case Update API is working
// import { getBookmark } from '../Hoc/UserBookmarks';
import moment from 'moment';
import { cleanName } from '../../utils/common';
import notify from '../NotificationModal';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../routePaths';
import { replaceAll } from '../../utils';

export const getBookmarkName = (data) => {
  if (data) {
    const name = data?.bookmarkname || data?.name;

    if (name) {
      let splitName = name.split('-');
      let time = isNaN(splitName[splitName?.length - 1]) ? moment().unix() : splitName.pop();

      return {
        bookmarkSavedName: replaceAll(name, `-${time}`, ''),
        bookmarkSavedTime: time,
      };
    }
  }

  return {
    bookmarkSavedName: '',
    bookmarkSavedTime: moment().unix(),
  };
};

const SaveBookmarkDetails = ({ queryParams = '', disabled = false, types = false, ...props }) => {
  const [store] = useContext(Context);
  const [context, setContext] = useContext(Context);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  let { bookmarkSavedName, bookmarkSavedTime } = getBookmarkName(props.data);
  const [loading, setLoading] = useState(false);
  const initialName = cleanName(bookmarkSavedName || props.name, ' ', false, InputRegex.commonName);
  const [name, setName] = useState(initialName);
  const [time, setTime] = useState(bookmarkSavedTime);
  const [bookmarkID, setBookmarkID] = useState(props.data?.id || 0);
  const [isEdit, setEdit] = useState(props.edit ? true : false);
  const [isSaveBookmark, setIsSaveBookmark] = useState(false);
  const history = useHistory();

  const { datasetID = [], mode = 'single', iconOnly = false, updateAll = false } = props;
  const { exportAppliedFilters } = useAppliedFilterContext();

  useEffect(() => {
    let { bookmarkSavedName, bookmarkSavedTime } = getBookmarkName(props.data);
    setName(cleanName(bookmarkSavedName ? bookmarkSavedName : props.name, ' ', false, InputRegex.commonName));
    setTime(bookmarkSavedTime);
    setEdit(props.data?.id ? true : false);
    setBookmarkID(props.data?.id || 0);
  }, [props.data, props.name]);

  useEffect(() => {
    if (context.isAuth && isSaveBookmark) {
      openSave();
    } else if (!context.showLogin) {
      setIsSaveBookmark(false);
    }

    // eslint-disable-next-line
  }, [context.isAuth, context.showLogin, isSaveBookmark]);

  const onSaveBookmarkDetails = async (values) => {
    if (!values.bookmarkName?.trim()) return;
    const format = replaceAll(
      JSON.stringify(exportAppliedFilters(datasetID, store.profile.email, types, queryParams)),
      '"',
      '\\"'
    );
    const body = {
      ip_bookmarkname: `${values.bookmarkName}-${time}`,
      ip_state: isEdit && !updateAll ? `${values.ip_state || '*'}` : mode,
      bookmarkstring: isEdit && !updateAll ? props.data?.bookmark_data : `${replaceAll(format, "'", "''''")}`,
      ip_bookmarkid: `${bookmarkID || 0}`,
    };

    setLoading(true);
    let resp = await setBookmarkDetails(body);
    let ID = resp.records?.[0]?.id;
    postClickStreamEvent({
      activity: ACTIVITIES.SAVE_BOOKMARK,
      userid: store?.profile?.email || '',
      bookmarkid: `${ID}`,
    });

    if (!resp.isError && ID) {
      notify.success({
        message: bookmarkID ? t('BookmarkUpdated_Success') : t('BookmarkCreate_Success'),
        onLinkClick: !bookmarkID
          ? () => {
              history.push(`${RoutePaths.PROFILE}/bookmarks`);
            }
          : false,
        linkText: 'ViewBookmarks',
      });

      /**
       * WIP : Update dosent work so incase api fixed we can enable this
       */
      // const bookmarkData = await getBookmark(ID);
      // setBookmarkID(ID);
      // setEdit(ID ? true : false);
      // let { bookmarkSavedName, bookmarkSavedTime } = getBookmarkName({ name: bookmarkData.name });

      let { bookmarkSavedName, bookmarkSavedTime } = getBookmarkName();
      setName(bookmarkSavedName);
      setTime(bookmarkSavedTime);

      if (typeof props.onSave === 'function') {
        props.onSave();
      }

      setIsModalVisible(false);
    } else {
      notify.error({ message: t('BookmarkCreate_Failed') });
      Sentry.captureException('Failed to create bookmark');
    }
    setLoading(false);
  };

  const authenticateSave = (e) => {
    if (!context.isAuth) {
      setContext((state) => ({
        ...state,
        showLogin: true,
      }));
      setIsSaveBookmark(true);
      e.preventDefault();
    } else {
      openSave();
    }
  };

  const openSave = () => {
    setIsModalVisible(true);
    setIsSaveBookmark(false);
    setName(initialName);
  };

  const actions = {
    defaultActions: () => {
      return (
        <FatLanguageButton
          text={isEdit ? t('Edit') : t('save')}
          tooltip={isEdit ? t('EditBookmark') : t('SaveAsBookmark')}
          iconLeft={
            isEdit ? (
              <img src="/static/assets/images/icon-pencil.svg" alt="Edit" className={`cursor-pointer `} />
            ) : (
              <BookOutlined />
            )
          }
          onClick={authenticateSave}
          type="link"
          className="font-16 font-weight-semi-bold joyride-dataset-step6"
        />
      );
    },
    iconOnly: () => {
      return (
        <Tooltip
          title={isEdit ? t('EditBookmark') : t('SaveAsBookmark')}
          getPopupContainer={(triggerNode) => triggerNode}
        >
          {isEdit ? (
            <img
              src="/static/assets/images/icon-pencil.svg"
              alt={t('Edit')}
              className="cursor-pointer"
              onClick={authenticateSave}
            />
          ) : (
            <BookOutlined onClick={authenticateSave} />
          )}
        </Tooltip>
      );
    },
  };

  return (
    <>
      {iconOnly ? actions.iconOnly() : actions.defaultActions()}
      {isModalVisible && (
        <Modal
          confirmLoading={loading}
          open={true}
          title={isEdit ? t('EditBookmark') : t('SaveAsBookmark')}
          closable={false}
          okText={t('save')}
          okButtonProps={{ disabled: !name }}
          cancelText={t('Cancel')}
          onCancel={() => {
            setIsModalVisible(false);
            setName(initialName);
          }}
          onOk={() => {
            form.validateFields().then((values) => {
              onSaveBookmarkDetails(values);
              form.resetFields();
            });
          }}
          closeIcon={<CloseButton />}
        >
          <Form
            form={form}
            name="bookmarksForm"
            initialValues={{ bookmarkName: name }}
            size="large"
            fields={[
              {
                name: 'bookmarkName',
                value: name,
              },
            ]}
          >
            <Form.Item
              name="bookmarkName"
              label={t('Enter_bookmark')}
              rules={[
                { required: true, message: t('RequiredField') },
                { pattern: InputRegex.BookmarkName, message: t('EnterValidBoommarkName') },
              ]}
              className="m-0"
            >
              <Input.TextArea
                placeholder={t('Enter_bookmark')}
                onChange={(e) => {
                  const inputedName = cleanName(e.target.value, ' ', false, InputRegex.commonName).replace(
                    /\s\s+/gi,
                    ' '
                  );
                  setName(inputedName);
                }}
                autoComplete="off"
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default SaveBookmarkDetails;
