import React, { useContext } from 'react';
import { Modal, Button, Checkbox, Alert } from '../../components';
import { ScreenSize } from '../../constants';
import Context from '../../context';
import s from './index.module.less';
import { CloseButton } from '../../shared';
import { t } from 'i18next';

const ModalPopup = ({
  onReturn,
  open,
  complete,
  title = '',
  cancel = false,
  proceed = true,
  content = '',
  width = 600,
  skip = false,
}) => {
  const [context] = useContext(Context);
  const handleProceed = () => {
    onReturn();
  };
  const handleCancel = () => {
    complete();
  };

  return (
    <>
      <Modal
        title={title || t('Notice')}
        open={open}
        centered
        onCancel={handleCancel}
        width={width}
        footer={[
          skip && (
            <div className="float-left" key="skip">
              <Checkbox onChange={skip.onChange}>{skip.title}</Checkbox>
            </div>
          ),
          cancel && (
            <Button key="cancel" onClick={handleCancel}>
              {t('Cancel')}
            </Button>
          ),
          proceed && (
            <Button key="submit" type="primary" onClick={handleProceed}>
              {t('Proceed')}
            </Button>
          ),
          !proceed && (
            <Button key="submit" type="primary" onClick={handleProceed}>
              {t('StartDemo')}
            </Button>
          ),
        ]}
        content={content}
        closeIcon={<CloseButton />}
      >
        {content && <div>{content}</div>}
        {!content && (
          <div className={s.modalContent}>
            {context.screenContext === ScreenSize.Desktop && (
              <Alert message={t('mergeWarningText')} type="warning" className="mb-3" />
            )}
            {t('NDAPStandardText_one', { domain: t(context.domain) })}{' '}
            <a href="/info?tab=standardization" className="mr-1">
              <b>
                <u>{t('NDAPStandardText_two', { domain: t(context.domain) })}</u>
              </b>
            </a>{' '}
            {t('NDAPStandardText_three')}{' '}
            <a href="/info?tab=termsandconditions" className="mr-1">
              <b>
                <u>{t('NDAPStandardText_four')}</u>
              </b>
            </a>{' '}
            {t('NDAPStandardText_five', { domain: t(context.domain) })}
          </div>
        )}
      </Modal>
    </>
  );
};

export default ModalPopup;
