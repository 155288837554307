import { LoadingOutlined, MailOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import React from 'react';
import jwt from 'jsonwebtoken';
import * as Sentry from '@sentry/browser';
import { Modal, Form, Input, Select, Button, message } from '../../../components';
import { CloseButton } from '../../../shared';
import { UserDesignations, InputRegex } from '../../../constants';
import { voidFunction } from '../../../utils';
import ChangePassword from '../ChangePassword';
import Context from '../../../context';
import { updatePofile } from '../../../services';
import AvatarUpload from '../../../shared/AvatarUpload';
import { Auth } from '../../../services';
const { Option } = Select;

const EditProfile = ({ show = true, width = 400, onClose = voidFunction }) => {
  const [form] = Form.useForm();
  const [showChangePassword, setChangePassword] = React.useState(false);
  const [authContext, setContext] = React.useContext(Context);
  const [isUpdating, setUpdating] = React.useState(false);

  const handleClose = () => {
    onClose();
    setChangePassword(false);
  };

  const handleSubmit = async (values) => {
    try {
      setUpdating(true);
      const result = await updatePofile({
        userType: values['custom:UserType'],
        name: values['name'],
      });
      if (result.data.isError) {
        message.error(t('Profile_Error'));
      } else {
        const token = await Auth.getTokenAndRefreshOnExpire(true);

        setContext((state) => {
          const newState = {
            ...state,
            profile: jwt.decode(token),
            token: token,
          };
          return newState;
        });

        sessionStorage.setItem('token', token);
        message.success(t('Profile_updated'));
      }
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      message.error(t('Profile_Error'));
      Sentry.captureException(`Failed to update profile, ` + error);
    }
  };

  return (
    <>
      <Modal
        wrapClassName="overflow-auto"
        style={{ top: 25 }}
        title={t('Edit_Profile')}
        open={show && !showChangePassword}
        width={width}
        footer={null}
        onCancel={handleClose}
        closeIcon={<CloseButton />}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            name: authContext?.profile?.name,
            email: authContext?.profile?.email,
            'custom:UserType': authContext?.profile['custom:UserType'],
          }}
          autoComplete="off"
          onFinish={!isUpdating && handleSubmit}
        >
          <div className="text-center">
            <AvatarUpload />
          </div>
          <Form.Item
            name="name"
            label={t('Name')}
            required
            rules={[
              { required: true, message: t('Input_Name') },
              { min: 6, message: t('minLength') },
              { max: 50, message: t('maxLength') },
              { pattern: InputRegex.UserName, message: t('Input_Valid_Name') },
            ]}
            className="mb-3"
          >
            <Input placeholder={t('Input_Name')} addonBefore={<UserOutlined />} size="large" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="email"
            label={t('Email')}
            rules={[
              { required: true, message: t('Input_Email') },
              { pattern: InputRegex.Email, message: t('Input_Valid_Email') },
            ]}
            className="mb-3"
          >
            <Input
              placeholder={t('Input_Email')}
              addonBefore={<MailOutlined />}
              disabled
              size="large"
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item
            name="custom:UserType"
            label={t('User_Role')}
            rules={[{ required: true, message: t('Input_role') }]}
            required
            className="mb-3"
          >
            <Select placeholder={t('User_Role')} suffixIcon={<UserSwitchOutlined />} size="large">
              {UserDesignations.map((type) => (
                <Option key={type.id} value={type.title}>
                  {type.title}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Button
            type="link"
            htmlType="button"
            className="w-100"
            onClick={() => setChangePassword(!showChangePassword)}
          >
            {t('Change_Pwd')}
          </Button>

          <Button type="primary" className="w-100 mt-3" size="large" htmlType="submit">
            {isUpdating ? <LoadingOutlined spin /> : null}
            {isUpdating ? t('SavingChanges') : t('SaveChanges')}
          </Button>
        </Form>
      </Modal>
      <ChangePassword
        show={showChangePassword}
        width={width}
        back={() => setChangePassword(!showChangePassword)}
        onClose={handleClose}
      />
    </>
  );
};

export default EditProfile;
