import React, { useRef, useEffect, useState, useContext } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import {
  SoundFilled,
  GlobalOutlined,
  QuestionOutlined,
  CaretDownOutlined,
  ArrowRightOutlined,
  CloseCircleOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Dropdown, Menu, Drawer } from '../../components';
import { RoutePaths } from '../../routePaths';
import ScreenReader from './ScreenReader';
import { postClickStreamEvent } from '../../services';
import * as constants from '../../constants';
import i18n from '../../i18n';
import Login from '../Login';
import Context from '../../context';
import { calcDrawerWidth } from '../../utils';
import { CloseButton } from '../../shared';
import s from './index.module.less';

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
};

const getDynamicPath = (domain) => domain;

const Header = () => {
  const [context, setContext] = useContext(Context);
  const [screenReaderVisible, setScreenReaderVisible] = useState(false);
  const [drawerMenu, setDrawerMenu] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [dynamicComponent, setDynamicComponent] = useState(null);
  const urlPath = usePathname();
  const currentLanguage = i18n.language;
  const history = useHistory();
  const { languages } = constants.getDomainParameters();

  useEffect(() => {
    const loadDynamicComponent = async () => {
      const dynamicModule = await import(`./${getDynamicPath(context.domain)}`);
      setDynamicComponent(() => dynamicModule.default);
    };

    loadDynamicComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchValue('');
    if (constants?.HIDE_HEADER_SEARCH?.includes(urlPath)) {
      setShowSearchBar(false);
    } else {
      setShowSearchBar(true);
    }
  }, [urlPath]);

  const changeLanguage = (e) => {
    try {
      i18n.changeLanguage(e.key);
      setContext((state) => ({ ...state, preferences: { ...state.preferences, lang: e.key } }));
      localStorage.setItem('lang', e.key);
    } catch {
      window.location.reload();
      console.log('Unable to change language');
    } finally {
      postClickStreamEvent({
        activity: constants.ACTIVITIES.CHANGE_LANGUAGE,
        userid: context?.profile?.email || '',
        from_language: `${languages[context?.preferences?.lang || 'en'].englishLabel}`,
        to_language: `${languages[e.key].englishLabel}`,
      });
    }
  };

  const currentSize = useRef(constants.DEFAULT_FONTSIZE);

  const changeFontSize = (mode) => {
    const sizes = constants.FONT_SIZES;
    let updateSize = false;

    if ([constants.FONT_MODE_INCREASE, constants.FONT_MODE_DECREASE].includes(mode)) {
      let index = sizes.findIndex((size) => size === currentSize.current);

      if (constants.FONT_MODE_INCREASE === mode) {
        index += 1;
      } else {
        index -= 1;
      }

      if (constants.FONT_SIZES[index]) {
        currentSize.current = constants.FONT_SIZES[index];
        updateSize = true;
      }
    } else if (currentSize.current !== constants.DEFAULT_FONTSIZE) {
      currentSize.current = constants.DEFAULT_FONTSIZE;
      updateSize = true;
    }

    if (updateSize) setContext((state) => ({ ...state, fontSize: currentSize.current }));
  };

  useEffect(() => {
    // wip remove when implementing font size as p tags
    const elementManiputale = (classTobeManipulated) => {
      for (let el of document.getElementsByClassName(classTobeManipulated)) {
        constants.FONT_SIZES.forEach((size) => {
          el.classList.remove(`zoomSize${size}`);
        });
        el.classList.add(`zoomSize${context.fontSize}`);
      }
    };
    elementManiputale('ant-drawer-content');
    elementManiputale('ant-layout-content');
    elementManiputale('ant-layout-sider-children');
    // eslint-disable-next-line
  }, [context.fontSize]);

  const onScreenReaderClick = () => {
    setScreenReaderVisible(true);
    postClickStreamEvent({
      activity: constants.ACTIVITIES.SCREEN_READER,
      userid: context?.profile?.email || '',
    });
  };

  const getPageNameSimpleLogic = () => {
    const pathName = document.location.pathname;
    if (pathName.includes('dataset')) {
      return 'dataset';
    }
    if (pathName.includes('sector')) {
      return 'sector';
    }
    if (pathName.includes('catalogue')) {
      return 'catalogue';
    }
    if (pathName.includes('merge')) {
      return 'merge';
    }
    if (pathName === '/') {
      return 'home';
    }
  };

  const onFaqClick = () => {
    postClickStreamEvent({
      activity: constants.ACTIVITIES.HELP,
      userid: context?.profile?.email || '',
      page: getPageNameSimpleLogic(),
    });
    history.push(`${RoutePaths.HELP}`);
    setDrawerMenu(false);
  };

  const languageMenu = (
    <Menu>
      {Object.keys(languages).map((key) => (
        <Menu.Item key={key} onClick={changeLanguage} className={localStorage.getItem('lang') === key && 'selected'}>
          <h4>{languages[key].label} </h4>
        </Menu.Item>
      ))}
    </Menu>
  );

  const openDrawerMenu = (val) => {
    setDrawerMenu(val);
  };

  const langMap = languages[context.preferences.lang];
  const lang = langMap ? langMap.label : 'English';

  if (!dynamicComponent) {
    return <></>;
  }

  const DynamicComponent = dynamicComponent;

  return (
    <header>
      <DynamicComponent
        languageMenu={languageMenu}
        lang={lang}
        onFaqClick={onFaqClick}
        changeFontSize={changeFontSize}
        currentSize={currentSize}
        onScreenReaderClick={onScreenReaderClick}
        urlPath={urlPath}
        showSearchBar={showSearchBar}
        searchValue={searchValue}
        openDrawerMenu={openDrawerMenu}
        currentLanguage={currentLanguage}
      />

      <Drawer
        onClose={() => setScreenReaderVisible(false)}
        closeIcon={null}
        width={calcDrawerWidth()}
        placement="right"
        open={screenReaderVisible}
      >
        <ScreenReader onClose={() => setScreenReaderVisible(false)} />
      </Drawer>

      <Drawer
        onClose={() => setDrawerMenu(false)}
        closeIcon={null}
        width={calcDrawerWidth(true, 0)}
        placement="right"
        open={drawerMenu}
        className={s.responsiveDrawer}
      >
        <div className={s.menuContainer}>
          <Row gutter={8} justify="end" align="middle" className={[s.header]}>
            <Col span={20}>
              <Link to={RoutePaths.HOME}>
                <img src="/static/assets/images/ndap_logo_final.png" height="50" alt="ndap-logo" />
              </Link>
            </Col>
            <Col span={3}>
              <CloseButton type="white" onClick={() => setDrawerMenu(false)} />
            </Col>
          </Row>
          <h2 className={s.menuText}>{t('Menu')}</h2>
          <Row justify="center">
            <Col span={16}>
              <Row>
                <Col xs={24}>
                  <Login avatar={false} closeDrawer={() => setDrawerMenu(false)} />
                </Col>
              </Row>

              <Row>
                <Col xs={24}>
                  <Link to={RoutePaths.NEWS_LETTER} onClick={() => setDrawerMenu(false)}>
                    <Button type="primary" block>
                      <MailOutlined />
                      {t('NewsLetter')}
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <Link to={RoutePaths.CATALOGUE} onClick={() => setDrawerMenu(false)}>
                    <Button type="primary" block>
                      {t('Catalogue')}
                      <ArrowRightOutlined />
                    </Button>
                  </Link>
                </Col>
              </Row>

              <Row>
                <Col xs={24}>
                  <Button type="primary" block onClick={onScreenReaderClick}>
                    <SoundFilled /> {t('Tscreenreader')}
                  </Button>
                </Col>
              </Row>
              {urlPath === '/' && context.domain === constants.ALL_DOMAINS.kadap && (
                <Row>
                  <Col xs={24}>
                    <Link to={RoutePaths.USECASEDASHBOARD} onClick={() => setDrawerMenu(false)}>
                      <Button type="primary" block>
                        {t('UseCaseDashboard')}
                        <ArrowRightOutlined />
                      </Button>
                    </Link>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={24}>
                  <Dropdown
                    overlayStyle={{ height: '400px', overflowY: 'auto' }}
                    trigger={['click']}
                    overlay={languageMenu}
                  >
                    <Button type="primary" block>
                      <GlobalOutlined /> {lang} <CaretDownOutlined />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <Button type="primary" block onClick={onFaqClick}>
                    {t('FAQ')} <QuestionOutlined />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <Button type="danger" block onClick={() => setDrawerMenu(false)}>
                    {t('CloseMenu')}
                    <CloseCircleOutlined />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Drawer>
    </header>
  );
};

export default Header;
