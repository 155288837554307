import Pool from '../shared/Login/userPool';

class AuthService {
  get cognitoUser() {
    return Pool.getCurrentUser();
  }

  getSession = () => {
    return new Promise((resolve, reject) => {
      this.cognitoUser.getSession((error, session) => {
        if (error) {
          reject(error);
        }

        resolve(session);
      });
    });
  };

  getTokenAndRefreshOnExpire = (force = false) => {
    return new Promise(async (resolve, reject) => {
      if (!this.cognitoUser) {
        resolve(null);
        return;
      }

      try {
        const session = await this.getSession();

        if (!session.isValid() || force) {
          const refreshToken = session.getRefreshToken();

          this.cognitoUser.refreshSession(refreshToken, (error, refreshedSession) => {
            if (error) {
              // Handle refresh session error
              this.cognitoUser.signOut();
              sessionStorage.clear();
              reject(error);
            } else {
              // Use the access token instead of the ID token
              resolve(refreshedSession.getAccessToken().getJwtToken());
            }
          });
        } else {
          // Use the access token instead of the ID token
          resolve(session.getAccessToken().getJwtToken());
        }
      } catch (error) {
        if (this.cognitoUser) {
          // Handle any cleanup or logout logic here
          this.cognitoUser.signOut();
          sessionStorage.clear();
        }

        reject(error);
      }
    });
  };
}

const Auth = new AuthService();
export default Auth;
