import { useState } from 'react';
import { t } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';

import { Modal, Button } from '../../components';
import DatasetAllDimensionFilter from './DatasetAllDimensionFilter';
import DatasetModalTitle from '../DatasetModalTitle';
import { useFilterContext, useAppliedFilterContext, useFilterActions } from '../../providers/FilterContextProvider';
import { CloseButton } from '../../shared';
import { TYPE_ALL_DIMENSIONS } from '../../constants';

const DatasetAllDimensions = ({
  sourceID,
  sourceName = '',
  dimensions = [],
  applyModalTo = null,
  colorCode = 0,
  icon = 'source',
  allow = false,
  onlyDimensionType = false,
  otherThenDimensionType = false,
  type = TYPE_ALL_DIMENSIONS,
}) => {
  const [resetSearch, setResetSearch] = useState(false);

  let { clearFilters, showFilter, getAny, setChecked } = useFilterContext();
  let { resetToPrevious } = useFilterActions({ sourceID, type });
  let { setAppliedFilter } = useAppliedFilterContext();
  let filteredDimensions = cloneDeep(dimensions);

  if (onlyDimensionType) {
    filteredDimensions = filteredDimensions.filter(
      (item) => (item.DimensionType || item.dimension_type || '').toLowerCase() === onlyDimensionType.toLowerCase()
    );
  }

  if (otherThenDimensionType) {
    filteredDimensions = filteredDimensions.filter(
      (item) => (item.DimensionType || item.dimension_type || '').toLowerCase() !== otherThenDimensionType.toLowerCase()
    );
  }

  const onApply = () => {
    setAppliedFilter({
      appliedFilter: getAny({
        type,
        sourceID,
        key: 'checked',
      }),
      quickPaths: getAny({
        type,
        sourceID,
        key: 'quickPaths',
      }),
      type,
      sourceID,
    });
    clearFilters();
    setResetSearch((ps) => !ps);
  };

  if (!sourceID) return null;

  return (
    <Modal
      onCancel={() => {
        resetToPrevious();
        setResetSearch((ps) => !ps);
      }}
      title={<DatasetModalTitle title={'SelectDimensions'} sourceName={sourceName} colorCode={colorCode} icon={icon} />}
      width={'70%'}
      style={{ top: 40 }}
      open={showFilter({ sourceID, type })}
      getContainer={applyModalTo}
      footer={
        <Button type="primary" onClick={onApply}>
          {t('Apply')}
        </Button>
      }
      closeIcon={<CloseButton />}
    >
      <DatasetAllDimensionFilter
        dimensions={filteredDimensions}
        onSelect={(data) => {
          setChecked({
            sourceID,
            type,
            data,
          });
        }}
        height={'30vh'}
        quickPaths={getAny({
          type,
          sourceID,
          key: 'quickPaths',
        })}
        allow={allow}
        resetSearch={resetSearch}
      />
    </Modal>
  );
};

export default DatasetAllDimensions;
