import { CognitoUserPool } from 'amazon-cognito-identity-js';
import * as config from '../../config';
import { getDomainParameters } from '../../constants';

// WIP: will need to check
// const { cognitoPoolId = config.REACT_APP_COGNITO_USER_POOL, cognitoClientId = config.REACT_APP_COGNITO_CLIENT_ID } =
//   getDomainParameters();

const userPoolData = getDomainParameters();

const poolData = {
  UserPoolId: userPoolData?.cognitoPoolId || config.REACT_APP_COGNITO_USER_POOL,
  ClientId: userPoolData?.cognitoClientId || config.REACT_APP_COGNITO_CLIENT_ID,
};

const CognitoUserPoolInstance = new CognitoUserPool(poolData);

export default CognitoUserPoolInstance;
